import request from '@/api'

/**
 * 登录
 */
export function login(data) {
    return request({
        url: 'user/login',
        method: 'post',
        data: data
    })
}

/**
 * 登录
 */
export function loginShow(data) {
    return request({
        url: 'show/in',
        method: 'post',
        params: data
    })
}

/**
 * 用户信息
 */
export function getUserInfo(userId) {
    return request({
        url: 'user/detail',
        method: 'post',
        params: {
            userId: userId
        }
    })
}

/**
 * 用户地址
 */
export function getUserAddress() {
    return request({
        url: 'sm/address/get',
        method: 'post',
    })
}

/**
 * 设置地址
 */
export function setUserAddress(addr) {
    return request({
        url: 'sm/address/set',
        method: 'post',
        data: addr
    })
}

/**
 * 修改昵称
 */
export function updateNickname(userId, nickname) {
    return request({
        url: 'user/update-nickname',
        method: 'post',
        params: {
            userId: userId,
            nickname: nickname
        }
    })
}

/**
 * 修改密码
 */
export function updatePassword(data) {
    return request({
        url: 'user/update-password',
        method: 'post',
        params: {
            oldPassword: data.oldPassword,
            newPassword: data.newPassword
        }
    })
}

/**
 * 修改手机号
 */
export function updatePhone(phone, password, code) {
    return request({
        url: 'user/update-phone',
        method: 'post',
        params: {
            phone: phone,
            password: password,
            code: code
        }
    })
}

/**
 * 发送激活邮件
 */
export function sendActivation(email, password) {
    return request({
        url: 'user/send-email',
        method: 'post',
        params: {
            email: email,
            password: password
        }
    })
}

