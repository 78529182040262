import Vue from 'vue'
import VueRouter from 'vue-router'
import homeLayout from '@/views/layout/homeLayout'
import consoleLayout from "@/views/layout/consoleLayout";
import workbenchLayout from "@/views/layout/workbenchLayout";
import {removeToken} from "@/utils/token";
import VXETable from 'vxe-table'
import 'vxe-table/lib/index.css'


Vue.use(VXETable);
// 注册组件
Vue.use(VueRouter)

export const constantRoutes = [
    {
        path: '/show',
        name: 'show',
        component: () => import('@/views/user/login/show.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/aiqcloud/login/index.vue')
    },
    {
        path: '/',
        name: 'Home',
        redirect: '/home',
        component: homeLayout,
        children: [
            {
                path: '/home',
                name: 'home',
                meta: {title: '首页'},
                component: () => import('@/views/aiqcloud/home/index.vue')
            },
            {
                path: '/none',
                name: 'none',
                component: () => import('@/views/aiqcloud/home/none')
            },
        ]
    },
    {
        path: '/ai',
        name: 'ai',
        redirect: '/ai',
        component: homeLayout,
        children: [
            {
                path: '/ai',
                name: 'ai',
                meta: {title: '视觉AI平台'},
                component: () => import('@/views/aiqcloud/ai/index.vue')
            },
        ]
    },
    {
        path: '/solution',
        name: 'solution',
        redirect: '/solution',
        component: homeLayout,
        children: [
            {
                path: '/solution',
                name: 'solution',
                meta: {title: '产品与解决方案'},
                component: () => import('@/views/aiqcloud/solution/index.vue')
            },
            {
                path: '/solution/expand',
                name: 'solution-expand',
                meta: {title: '数据增广平台'},
                component: () => import('@/views/aiqcloud/solution/expand.vue')
            },
            {
                path: '/solution/mark',
                name: 'solution-mark',
                meta: {title: '协同标注平台'},
                component: () => import('@/views/aiqcloud/solution/mark.vue')
            },
            {
                path: '/solution/model',
                name: 'solution-model',
                meta: {title: 'AI建模平台'},
                component: () => import('@/views/aiqcloud/solution/model.vue')
            },
            {
                path: '/solution/visual',
                name: 'solution-visual',
                meta: {title: '可编程视觉模组'},
                component: () => import('@/views/aiqcloud/solution/visual')
            },
            {
                path: '/solution/edge',
                name: 'solution-edge',
                meta: {title: '边缘一体机'},
                component: () => import('@/views/aiqcloud/solution/edge')
            },
            {
                path: '/solution/aoi',
                name: 'solution-aoi',
                meta: {title: 'AOI检测软件'},
                component: () => import('@/views/aiqcloud/solution/aoi')
            },
            {
                path: '/solution/packaging',
                name: 'solution-packaging',
                meta: {title: '包装行业解决方案'},
                component: () => import('@/views/aiqcloud/solution/packaging')
            },
            {
                path: '/solution/energy',
                name: 'solution-energy',
                meta: {title: '新能源行业解决方案'},
                component: () => import('@/views/aiqcloud/solution/energy')
            },
            {
                path: '/solution/medicine',
                name: 'solution-medicine',
                meta: {title: '医药行业解决方案'},
                component: () => import('@/views/aiqcloud/solution/medicine')
            },
            {
                path: '/solution/photovoltaic',
                name: 'solution-photovoltaic',
                meta: {title: '光伏行业解决方案'},
                component: () => import('@/views/aiqcloud/solution/photovoltaic')
            },
            {
                path: '/solution/electronics',
                name: 'solution-electronics',
                meta: {title: '3C电子行业解决方案'},
                component: () => import('@/views/aiqcloud/solution/electronics')
            },
            {
                path: '/solution/chip',
                name: 'solution-chip',
                meta: {title: '芯片行业解决方案'},
                component: () => import('@/views/aiqcloud/solution/chip')
            }
        ]
    },
    {
        path: '/case',
        name: 'case',
        redirect: '/case',
        component: homeLayout,
        children: [
            {
                path: '/case',
                name: 'case',
                meta: {title: '应用案例'},
                component: () => import('@/views/aiqcloud/case/index')
            },
            {
                path: '/case/pack',
                name: 'case-pack',
                meta: {title: '包装行业应用案例'},
                component: () => import('@/views/aiqcloud/case/pack')
            },
            {
                path: '/case/energy',
                name: 'case-energy',
                meta: {title: '新能源应用案例'},
                component: () => import('@/views/aiqcloud/case/energy')
            },
            {
                path: '/case/medicine',
                name: 'case-medicine',
                meta: {title: '医药行业应用案例'},
                component: () => import('@/views/aiqcloud/case/medicine')
            },
            {
                path: '/case/photovoltaic',
                name: 'case-photovoltaic',
                meta: {title: '光伏行业应用案例'},
                component: () => import('@/views/aiqcloud/case/photovoltaic')
            },
            {
                path: '/case/electronics',
                name: 'case-electronics',
                meta: {title: '3C电子行业应用案例'},
                component: () => import('@/views/aiqcloud/case/electronics')
            },
            {
                path: '/case/chip',
                name: 'case-chip',
                meta: {title: '芯片行业应用案例'},
                component: () => import('@/views/aiqcloud/home/none')
            },
        ]
    },
    {
        path: '/ecology',
        name: 'ecology',
        redirect: '/ecology',
        component: homeLayout,
        children: [
            {
                path: '/ecology',
                name: 'ecology',
                meta: {title: '生态合作'},
                component: () => import('@/views/aiqcloud/ecology/index')
            },
            {
                path: '/cooperation/form',
                name: 'cooperation-form',
                meta: {title: '生态合作申请'},
                component: () => import('@/views/aiqcloud/ecology/form.vue')
            },
            {
                path: '/cooperation/register',
                name: 'cooperation-register',
                meta: {title: '生态合作注册'},
                component: () => import('@/views/aiqcloud/ecology/register')
            },
            {
                path: '/cooperation/register/success',
                name: 'cooperation-register-success',
                meta: {title: '生态合作注册'},
                component: () => import('@/views/aiqcloud/ecology/success')
            }
        ]
    },
    {
        path: '/about',
        name: 'about',
        redirect: '/about',
        component: homeLayout,
        children: [
            {
                path: '/about',
                name: 'about',
                meta: {title: '关于我们'},
                component: () => import('@/views/aiqcloud/about/index')
            },
            {
                path: '/about/cvae',
                name: 'about-cvae',
                component: () => import('@/views/aiqcloud/about/cvae')
            },
            {
                path: '/about/mae',
                name: 'about-mae',
                component: () => import('@/views/aiqcloud/about/mae')
            },
            {
                path: '/about/aivae',
                name: 'about-aivae',
                component: () => import('@/views/aiqcloud/about/aivae')
            },
            {
                path: '/about/apply',
                name: 'about-apply',
                component: () => import('@/views/aiqcloud/about/apply')
            }
        ]
    },
    //项目市场
    {
        path: '/market',
        component: homeLayout,
        children: [
            {
                path: '/market',
                name: 'market',
                meta: {title: '项目市场'},
                component: () => import('@/views/market/market')
            }, {
                path: '/market/project',
                name: 'market-project',
                meta: {title: '项目详情'},
                component: () => import('@/views/market/market_project')
            }, {
                path: '/market/tender',
                name: 'market-tender',
                meta: {title: '项目投标'},
                component: () => import('@/views/market/market_tender')
            },
            {
                path: '/market/mine/tender',
                name: 'market-mine-tender',
                meta: {title: '我的投标'},
                component: () => import('@/views/market/market_mine_tender')
            },
            {
                path: '/market/tender/detail',
                name: 'market-tender-detail',
                meta: {title: '投标详情'},
                component: () => import('@/views/market/tender_detail'),
            },
        ],
    },
    // 左侧菜单显示
    {
        path: '/console/project',
        component: consoleLayout,
        meta: {
            title: '项目管理',
            icon: 'project',
        },
        redirect: '/console/workbench',
        children: [
            {
                path: '/console/workbench',
                name: 'console-workbench',
                meta: {title: '工作台'},
                component: () => import('@/views/console/workbench'),
            }
        ]
    },
    {
        path: '/console/com',
        component: consoleLayout,
        meta: {
            title: '企业管理',
            icon: 'com',
        },
        redirect: '/console/com/overview',
        children: [
            // {
            //     path: '/console/com/overview',
            //     name: 'console-com-overview',
            //     meta: {
            //         title: '数据总览',
            //     },
            //     component: () => import('@/views/console/com/overview'),
            // },
            {
                path: '/console/com/info',
                name: 'console-com-info',
                meta: {
                    title: '企业信息',
                },
                component: () => import('@/views/console/com/info'),
            },
            {
                path: '/console/com/user',
                name: 'console-com-user',
                meta: {
                    title: '用户管理',
                },
                component: () => import('@/views/console/com/user'),
            },
            {
                path: '/console/com/equip',
                name: 'console-com-equip',
                meta: {
                    title: '算力调度',
                },
                component: () => import('@/views/console/com/equip'),
            },
            {
                path: '/console/com/equip/detail',
                name: 'console-com-equip-detail',
                component: () => import('@/views/console/com/equip_detail'),
            },
            //用户详细信息
            {
                path: '/console/com/person/operate',
                name: 'console-com-person-operate',
                component: () => import('@/views/console/com/person_operate'),
            }
        ]
    },
    // 模型中心
    {
        path: '/platform/model',
        component: workbenchLayout,
        meta: {
            title: '模型中心',
            icon: 'computer',
        },
        redirect: '/console/platform/model',
        children: [
            {
                path: '/console/platform/model/introduce',
                meta: {
                    title: '简介',
                },
                component: () => import('@/views/console/platform/ws_introduce')
            },
            {
                path: '/console/platform/dataset/file',
                component: () => import('@/views/console/platform/dataset_file')
            },
            {
                path: '/console/platform/model',
                meta: {
                    title: '模型列表',
                },
                component: () => import('@/views/console/platform/model'),
            },
            {
                path: '/console/platform/train',
                meta: {
                    title: '模型训练',
                },
                component: () => import('@/views/console/platform/train')
            },
            {
                path: '/console/platform/testlist',
                meta: {
                    title: '模型校验',
                },
                component: () => import('@/views/console/platform/testlist')
            },
            {
                path: '/console/platform/check',
                meta: {
                    title: '校验集',
                },
                component: () => import('@/views/console/platform/check')
            },
            {
                path: '/console/platform/model/version',
                component: () => import('@/views/console/platform/model_version')
            },
            {
                path: '/console/platform/model/train',
                component: () => import('@/views/console/platform/model_train')
            },
            {
                path: '/console/platform/train/data',
                component: () => import('@/views/console/platform/train_data')
            },
            {
                path: '/console/platform/train/data/yolo',
                component: () => import('@/views/console/platform/train_data_yolo')
            },
            {
                path: '/console/platform/train/output',
                component: () => import('@/views/console/platform/train_output')
            },
            {
                path: '/console/platform/check/img',
                component: () => import('@/views/console/platform/check_img')
            },
            {
                path: '/console/platform/model/test',
                component: () => import('@/views/console/platform/model_test')
            },
            {
                path: '/console/platform/testend',
                component: () => import('@/views/console/platform/testend')
            },
            {
                path: '/console/platform/uploadtestmore',
                component: () => import('@/views/console/platform/uploadtestmore')
            },
            {
                path: '/console/platform/testendmore',
                component: () => import('@/views/console/platform/testendmore')
            },
            {
                path: '/console/platform/testendmore2',
                component: () => import('@/views/console/platform/testendmore2')
            }
        ]
    },
    // 数据标注
    {
        path: '/platform/mark',
        component: workbenchLayout,
        meta: {
            title: '标注服务',
            icon: 'menu-01',
        },
        redirect: '/console/mark/dataset',
        children: [
            {
                path: '/console/mark/dataset/introduce',
                meta: {
                    title: '简介',
                },
                component: () => import('@/views/console/mark/mark_introduce'),
            },
            {
                path: '/console/mark/dataset',
                name: 'console-mark-dataset',
                meta: {
                    title: '数据集',
                },
                component: () => import('@/views/console/mark/dataset')
            },
            {
                path: '/console/mark/dataset/task',
                name: 'console-mark-dataset-task',
                meta: {
                    title: '我的任务',
                },
                component: () => import('@/views/console/mark/dataset_task')
            },
            {
                path: '/console/mark/mark01',
                name: 'console-mark-mark01',
                component: () => import('@/views/console/mark/mark01')
            },
            {
                path: '/console/mark/mark02',
                name: 'console-mark-mark02',
                component: () => import('@/views/console/mark/mark02')
            },
            {
                path: '/console/mark/mark03',
                name: 'console-mark-mark03',
                component: () => import('@/views/console/mark/mark03')
            },
            {
                path: '/console/mark/mark04',
                name: 'console-mark-mark04',
                component: () => import('@/views/console/mark/mark04')
            },

            {
                path: '/console/mark/markimg',
                name: 'console-mark-markimg',
                component: () => import('@/views/console/mark/markimg')
            },
            {
                path: '/console/mark/datasetmore',
                name: 'console-mark-datasetmore',
                component: () => import('@/views/console/mark/datasetmore')
            },
            {
                path: '/console/mark/distribution',
                name: 'console-mark-distribution',
                component: () => import('@/views/console/mark/dataset_mark_distribution')
            },
            {
                path: '/console/mark/modeldatasethis',
                name: 'console-mark-modeldatasethis',
                component: () => import('@/views/console/mark/modeldatasethis')
            },
            {
                path: '/console/mark/datasetmorehis',
                name: 'console-mark-datasetmorehis',
                component: () => import('@/views/console/mark/datasetmorehis')
            },
            {
                path: '/console/mark/mark04his',
                name: 'console-mark-mark04his',
                component: () => import('@/views/console/mark/mark04his')
            },
            {
                path: '/console/mark/markimghis',
                name: 'console-mark-markimghis',
                component: () => import('@/views/console/mark/markimghis')
            },
            {
                path: '/console/mark/markteam',
                name: 'console-mark-markteam',
                component: () => import('@/views/console/mark/markteam')
            },
            {
                path: '/console/mark/acceptlist',
                name: 'console-mark-acceptlist',
                component: () => import('@/views/console/mark/acceptlist')
            },
            {
                path: '/console/mark/acceptimg',
                name: 'console-mark-acceptimg',
                component: () => import('@/views/console/mark/acceptimg')
            },
            {
                path: '/console/mark/chatacceptimg',
                name: 'console-mark-chatacceptimg',
                component: () => import('@/views/console/mark/chatacceptimg')
            },

        ]
    },
    // 增广服务
    {
        path: '/platform/expand',
        component: workbenchLayout,
        meta: {
            title: '增广服务',
            icon: 'example',
        },
        redirect: '/console/expand/dataset',
        children: [
            {
                path: '/console/expand/dataset/introduce',
                meta: {
                    title: '简介',
                },
                component: () => import('@/views/console/expand/expand_introduce')
            },
            {
                path: '/console/expand/dataset',
                name: 'console-expand-dataset',
                meta: {
                    title: '数据集',
                },
                component: () => import('@/views/console/expand/dataset')
            },
            {
                path: '/console/expand/dataset/detail',
                name: 'console-expand-dataset-detail',
                component: () => import('@/views/console/expand/dataset_detail')
            },
            {
                path: '/console/expand/dataset/type',
                name: 'console-expand-dataset-type',
                component: () => import('@/views/console/expand/dataset_type')
            },
            {
                path: '/console/expand/dataset/tag/template',
                name: 'console-expand-dataset-tag-template',
                component: () => import('@/views/console/expand/dataset_tag_template')
            },
            {
                path: '/console/expand/dataset/tag',
                name: 'console-expand-dataset-tag',
                component: () => import('@/views/console/expand/dataset_tag')
            },
            {
                path: '/console/expand/dataset/img',
                name: 'console-expand-dataset-img',
                component: () => import('@/views/console/expand/dataset_img')
            },
            {
                path: '/console/expand/dataset/mark',
                name: 'console-expand-dataset-mark',
                component: () => import('@/views/console/expand/dataset_mark')
            },
            {
                path: '/console/expand/dataset/version',
                name: 'console-expand-dataset-version',
                component: () => import('@/views/console/expand/dataset_version')
            },
            {
                path: '/console/expand/dataset/expand',
                name: 'console-expand-dataset-expand',
                component: () => import('@/views/console/expand/dataset_expand')
            },
            {
                path: '/console/expand/dataset/expand/operation',
                name: 'console-expand-dataset-expand-operation',
                component: () => import('@/views/console/expand/dataset_expand_operation')
            },
            {
                path: '/console/expand/dataset/expand/expansion',
                name: 'console-expand-dataset-expand-expansion',
                component: () => import('@/views/console/expand/expansion')
            },
            {
                path: '/console/expand/forward',
                name: 'console-expand-forward',
                component: () => import('@/views/console/expand/forward')
            },
            {
                path: '/console/expand/defect',
                name: 'console-expand-defect',
                meta: {
                    title: '缺陷库',
                },
                component: () => import('@/views/console/expand/defect')
            }, {
                path: '/console/expand/sample',
                name: 'console-expand-sample',
                component: () => import('@/views/console/expand/sample')
            },
            {
                path: '/console/expand/depot/img',
                name: 'console-expand-depot-img',
                component: () => import('@/views/console/expand/depot_img')
            },
        ]
    },
    // 开发者中心
    {
        path: '/platform/dev',
        component: workbenchLayout,
        meta: {
            title: '开发者中心',
            icon: 'component',
        },
        redirect: '/console/dev/introduce',
        children: [
            {
                path: "/console/dev/introduce",
                name: "console-dev-introduce",
                meta: {
                    title: '简介',
                },
                component: () => import('@/views/console/dev/dev_introduce'),
            },
            {
                path: '/console/dev/notebook',
                name: 'console-dev-notebook',
                meta: {title: 'Notebook'},
                component: () => import('@/views/console/dev/notebook/home'),
            },
            {
                path: '/console/dev/mynotebook',
                name: 'console-dev-mynotebook',
                component: () => import('@/views/console/dev/notebook/my'),
            },
            {
                path: '/console/dev/notebook/file',
                name: 'console-dev-notebook-file',
                component: () => import('@/views/console/dev/notebook/nb_file'),
            },
            {
                path: '/console/dev/notebook/add',
                name: 'console-dev-notebook-add',
                component: () => import('@/views/console/dev/notebook/add'),
            },
            {
                path: '/console/dev/notebook/edit',
                name: 'console-dev-notebook-edit',
                component: () => import('@/views/console/dev/notebook/edit'),
            },
            {
                path: '/console/dev/notebook/detail',
                name: 'console-dev-notebook-detail',
                component: () => import('@/views/console/dev/notebook/detail'),
            },
            {
                path: '/console/dev/notebook/output',
                name: 'console-dev-notebook-output',
                component: () => import('@/views/console/dev/notebook/output'),
            },
            {
                path: '/console/dev/model',
                name: 'console-dev-model',
                component: () => import('@/views/console/dev/model'),
            },
            {
                path: '/console/dev/mymodel',
                name: 'console-dev-mymodel',
                component: () => import('@/views/console/dev/model/myModel'),
            },
            {
                path: '/console/dev/modelSub',
                name: 'console-dev-modelSub',
                component: () => import('@/views/console/dev/model/modelSub'),
            },
            {
                path: '/console/dev/model/add',
                name: 'console-dev-model-add',
                component: () => import('@/views/console/dev/model/add'),
            },
            {
                path: '/console/dev/model/mydetail',
                name: 'console-dev-model-mydetail',
                component: () => import('@/views/console/dev/model/myDetail'),
            },
            {
                path: '/console/dev/model/buy',
                name: 'console-dev-model-buy',
                component: () => import('@/views/console/dev/model/buy'),
            },
            {
                path: '/console/dev/model/detail',
                name: 'console-dev-model-detail',
                component: () => import('@/views/console/dev/model/detail'),
            },
            {
                path: '/console/dev/dataset',
                name: 'console-dev-dataset',
                component: () => import('@/views/console/dev/dataset')
            },
            {
                path: '/console/dev/dataset/add',
                name: 'console-dev-dataset-add',
                component: () => import('@/views/console/dev/dataset/add'),
            },
            {
                path: '/console/dev/dataset/detail',
                name: 'console-dev-dataset-detail',
                component: () => import('@/views/console/dev/dataset/detail'),
            },
            {
                path: '/console/dev/dataset/my',
                name: 'console-dev-dataset-my',
                component: () => import('@/views/console/dev/dataset/my'),
            },
            {
                path: '/console/dev/dataset/sub',
                name: 'console-dev-dataset-sub',
                component: () => import('@/views/console/dev/dataset/sub'),
            },
            {
                path: '/console/dev/dataset/edit',
                name: 'console-dev-dataset-edit',
                component: () => import('@/views/console/dev/dataset/edit'),
            },
            {
                path: '/console/dev/drag',
                name: 'console-dev-drag',
                meta: {title: '图形化开发'},
                component: () => import('@/views/console/dev/drag'),
            },
            {
                path: '/console/dev/drag/add',
                name: 'console-dev-drag-add',
                component: () => import('@/views/console/dev/drag/add'),
            },
            {
                path: '/console/dev/drag/detail',
                name: 'console-dev-drag-detail',
                component: () => import('@/views/console/dev/drag/detail'),
            },
            {
                path: '/console/dev/drag/download',
                name: 'console-dev-drag-download',
                component: () => import('@/views/console/dev/drag/download'),
            },
            {
                path: '/console/dev/drag/edit',
                name: 'console-dev-drag-edit',
                component: () => import('@/views/console/dev/drag/edit'),
            },
            {
                path: '/console/dev/drag/my',
                name: 'console-dev-drag-my',
                component: () => import('@/views/console/dev/drag/my'),
            },
            {
                path: '/console/dev/drag/myComponents',
                name: 'console-dev-drag-myComponents',
                component: () => import('@/views/console/dev/drag/myComponents'),
            }
        ]
    },
    //边缘服务
    {
        path: '/platform/edge',
        component: workbenchLayout,
        meta: {
            title: '边缘服务',
            icon: 'meachine',
        },
        redirect: '/console/edge/introduce',
        children: [
            {
                path: "/console/edge/introduce",
                name: "console-edge-introduce",
                meta: {
                    title: '简介',
                },
                component: () => import('@/views/console/edge/edge_introduce'),
            },
            {
                path: '/console/edge/watch',
                name: 'console-edge-watch',
                meta: {
                    title: '边缘看板',
                },
                component: () => import('@/views/console/edge/watch'),
            },
            {
                path: '/console/edge',
                name: 'console-edge',
                meta: {
                    title: '边缘管理',
                },
                component: () => import('@/views/console/edge'),
            },
            {
                path: '/console/edge/program',
                name: 'console-edge-program',
                meta: {
                    title: '程序管理',
                },
                component: () => import('@/views/console/edge/program'),
            },
            {
                path: '/console/edge/device',
                name: 'console-edge-device',
                component: () => import('@/views/console/edge/device'),
            },
            {
                path: '/console/edge/img',
                name: 'console-edge-img',
                component: () => import('@/views/console/edge/img'),
            },
            {
                path: '/console/edge/model/push',
                name: 'console-edge-model-push',
                component: () => import('@/views/console/edge/model_push'),
            },
            {
                path: '/console/edge/program/version',
                name: 'console-edge-program-version',
                component: () => import('@/views/console/edge/program_version'),
            },
        ]
    },
    // 下载管理
    {
        path: '/platform/download',
        component: workbenchLayout,
        meta: {
            title: '下载中心',
            icon: 'zip',
        },
        children: [
            {
                path: '/console/platform/download',
                name: 'console-platform-download',
                meta: {
                    title: '下载列表'
                },
                component: () => import('@/views/console/download/platform_download')
            }
        ]
    },
    {
        path: '/console/person',
        component: consoleLayout,
        hidden: true,
        meta: {
            title: '个人中心',
            icon: 'user'
        },
        redirect: '/console/person/info',
        children: [
            // 个人信息
            {
                path: '/console/person/info',
                name: 'person-info',
                component: () => import('@/views/console/account/info'),
            },
            {
                path: '/console/person/password',
                name: 'person-password',
                component: () => import('@/views/console/account/password'),
            }, {
                path: '/console/person/phone',
                name: 'person-phone',
                component: () => import('@/views/console/account/phone'),
            }, {
                path: '/console/person/email',
                name: 'person-email',
                component: () => import('@/views/console/account/email'),
            },
            {
                path: '/console/person/address',
                name: 'person-address',
                component: () => import('@/views/console/account/address'),
            },
            // 消息
            {
                path: '/console/person/msg',
                name: 'console-msg',
                component: () => import('@/views/console/message')
            },
            {
                path: '/console/person/msg/detail',
                name: 'console-msg-detail',
                component: () => import('@/views/console/message/detail')
            },
        ]
    },
    // 服务与支持
    {
        path: "/console/support",
        component: consoleLayout,
        hidden: true,
        meta: {
            title: '服务与支持',
            icon: 'example'
        },
        redirect: '/console/support/work-order',
        children: [
            // {
            //     path: '/console/support/faq',
            //     name: 'console-support-faq',
            //     component: () => import('@/views/console/support/faq')
            // },
            {
                path: '/console/support/work-order',
                name: 'console-support-work-order',
                component: () => import('@/views/console/support/workOrder')
            },
        ]

    },
    {
        path: '/license/expire',
        name: 'license-expire',
        hidden: true,
        component: () => import('@/views/pages/license/expire.vue'),
    },
    {
        path: '*',
        name: 'error',
        hidden: true,
        component: () => import('@/views/pages/error/404.vue'),
    },
]


const router = new VueRouter({
    routes: constantRoutes
})

// 动态生成路由
export const asyncRoutes = []

const createRouter = () => new VueRouter({
    // mode: 'hash',
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})


export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export function back() {
    history.go(-1)
}

export function clearOut() {
    resetRouter()
    removeToken()
    window.localStorage.removeItem('name');
    window.localStorage.removeItem('avatar');
    window.localStorage.removeItem('userId');
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router
