<template>
  <div class="app-wrapper" :class="classObj">
    <sidebar class="sidebar-container" v-if="navbar.opened"/>
    <div class="main-container">
      <div v-if="navbar.opened" class="fixed-header">
        <navbar/>
      </div>
      <div class="fixed-main" :style="{marginTop: navbar.opened?'50px':'0px',
        background: nightMode.isNightMode ? nightMode.pageColor:''}">
        <app-main/>
      </div>
    </div>
  </div>
</template>

<script>

import {AppMain, Navbar, Sidebar} from './components'
import ResizeMixin from './mixin/ResizeHandler'
import {mapState} from 'vuex'

export default {
  name: 'workbenchLayout',
  components: {
    AppMain,
    Navbar,
    Sidebar,
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      navbar: state => state.app.navbar,
      nightMode: state => state.app.nightMode,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
      }
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
//@import "~@/styles/mixin.scss";
$sideBarWidth: 210px;

.app-wrapper {
  //@include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px)
}

.fixed-main {
  width: 100%;
  min-width: 1600px;
  margin-top: 50px;
 transition: all .3s;
}

.mobile .fixed-header {
  width: 100%;
}
</style>