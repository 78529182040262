import Cookies from 'js-cookie'

const state = {
    sidebar: {
        opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
        withoutAnimation: false
    },
    navbar: {
        opened: true
    },
    device: 'desktop',
    size: Cookies.get('size') || 'small',
    nightMode: {  //黑夜模式
        isNightMode: false,
        pageColor: "#ffffff",
    },
}
const mutations = {
    TOGGLE_SIDEBAR: state => {
        state.sidebar.opened = !state.sidebar.opened
        state.sidebar.withoutAnimation = false
        if (state.sidebar.opened) {
            Cookies.set('sidebarStatus', 1)
        } else {
            Cookies.set('sidebarStatus', 0)
        }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
        Cookies.set('sidebarStatus', 0)
        state.sidebar.opened = false
        state.sidebar.withoutAnimation = withoutAnimation
    },
    OPEN_SIDEBAR: (state, withoutAnimation) => {
        Cookies.set('sidebarStatus', 1)
        state.sidebar.opened = true
        state.sidebar.withoutAnimation = withoutAnimation
    },
    OPEN_NAVBAR: (state) => {
        state.navbar.opened = true
    },
    CLOSE_NAVBAR: (state) => {
        state.navbar.opened = false
    },
    TOGGLE_DEVICE: (state, device) => {
        state.device = device
    },
    SET_SIZE: (state, size) => {
        state.size = size
        Cookies.set('size', size)
    },
    SET_NIGHT_MODE: (state, nightMode) => {
        state.nightMode = nightMode
    },
}

const actions = {
    toggleSideBar({commit}) {
        commit('TOGGLE_SIDEBAR')
    },
    closeSideBar({commit}, {withoutAnimation}) {
        commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    openSideBar({commit}, {withoutAnimation}) {
        commit('OPEN_SIDEBAR', withoutAnimation)
    },
    closeNavbar({commit}) {
        commit('CLOSE_NAVBAR')
    },
    openNavbar({commit}) {
        commit('OPEN_NAVBAR')
    },
    toggleDevice({commit}, device) {
        commit('TOGGLE_DEVICE', device)
    },
    setSize({commit}, size) {
        commit('SET_SIZE', size)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
