import {asyncRoutes, constantRoutes} from '@/router'

const state = {
    routes: [],
    addRoutes: [],
    checkRoutes: false
}

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes
        state.routes = constantRoutes.concat(routes)
    },
    SET_CHECK: (state, check) => {
        state.checkRoutes = check
    },
}

const actions = {
    generateAllRoutes({commit}) {
        return new Promise(resolve => {
            let accessedRoutes
            accessedRoutes = asyncRoutes || []
            commit('SET_ROUTES', accessedRoutes)
            commit('SET_CHECK', true)
            resolve(accessedRoutes)
        })
    },
    setCheck: (state, check) => {
        state.checkRoutes = check
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
