import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import {getToken} from "@/utils/token";
import router from '../router'
import {clearOut} from "@/router";
import store from "@/store";

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 1000 * 300 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        if (getToken()) {
            config.headers['aitoken'] = getToken()
            config.headers['comId'] = store.getters.comId
            config.headers['wsId'] = store.getters.wsId
        }
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        //验证码错误不刷新
        if (res.msg === '验证码错误') {
            return res
        }
        if (res.code === -10) {
            store.dispatch('user/logout')
            router.push({path: '/license/expire'})
            return Promise.reject()
        }
        // 正常状态为1
        // 处理特殊操作 10001 = 验证码已过期
        if (res.code !== 1 && res.code !== 10001) {
            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
                if (getToken()) {
                    clearOut()
                }
                // to re-login
                MessageBox.confirm('您的会话已过期', '重新登录', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '返回首页',
                    type: 'warning',
                    showClose: false
                }).then(() => {
                    store.dispatch('user/logout')
                    router.push("/login");
                }).catch(() => {
                    store.dispatch('user/logout')
                    router.push("/home");
                })
            } else {
                Message({
                    message: res.msg || 'Error',
                    type: 'error',
                    showClose: true,
                    duration: 2000
                })
            }
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            showClose: true,
            duration: 2000
        })
        return Promise.reject(error)
    }
)

export default service
