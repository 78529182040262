import {login, loginShow} from '@/api/user'
import {resetRouter} from '@/router'
import {getToken, removeToken, setToken} from "@/utils/token";
import store from "@/store";

const state = {
    token: getToken(),
    name: window.localStorage.getItem('name'),
    avatar: window.localStorage.getItem('avatar'),
    userId: window.localStorage.getItem('userId'),
    comId: window.localStorage.getItem('comId'),
    wsId: window.localStorage.getItem('wsId'),
    wsName: window.localStorage.getItem('wsName'),
    wsType: window.localStorage.getItem('wsType'),
    redPointMyMsg: 0,        //未读消息数
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
        setToken(token)
    },
    SET_NAME: (state, name) => {
        state.name = name
        window.localStorage.setItem('name', name)
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
        window.localStorage.setItem('avatar', avatar)
    },
    SET_USERID: (state, userId) => {
        state.userId = userId
        window.localStorage.setItem('userId', userId)
    },
    SET_COM: (state, comId) => {
        state.comId = comId
        window.localStorage.setItem('comId', comId)
    },
    SET_WS: (state, wsId) => {
        state.wsId = wsId
        window.localStorage.setItem('wsId', wsId)
    },
    SET_WS_TYPE: (state, wsType) => {
        state.wsType = wsType
        window.localStorage.setItem('wsType', wsType)
    },
    SET_WS_NAME: (state, wsName) => {
        state.wsName = wsName
        window.localStorage.setItem('wsName', wsName)
    },
    SET_RED_POINT_MY_MSG: (state, redPointMyMsg) => {
        state.redPointMyMsg = redPointMyMsg
    },
}

const actions = {
    login({commit}, userInfo) {
        const {username, password, code, timestamp} = userInfo
        return new Promise((resolve, reject) => {
            login({username: username.trim(), password: password, code: code, timestamp: timestamp}).then(response => {
                if (response.code === 1) {
                    const {token, username, userId, comId} = response.data
                    commit('SET_TOKEN', token)
                    commit('SET_NAME', username)
                    commit('SET_COM', comId)
                    commit('SET_AVATAR', 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif')
                    commit('SET_USERID', userId)
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    show({commit}, userInfo) {
        const {username, password} = userInfo
        return new Promise((resolve, reject) => {
            loginShow({u: username, p: password}).then(response => {
                if (response.code === 1) {
                    const {token, username, userId, comId} = response.data
                    commit('SET_TOKEN', token)
                    commit('SET_NAME', username)
                    commit('SET_COM', comId)
                    commit('SET_AVATAR', 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif')
                    commit('SET_USERID', userId)
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // get user account
    getInfo({commit, state}) {
        return new Promise((resolve, reject) => {
            getInfo(state.token).then(response => {
                const {data} = response

                if (!data) {
                    reject('Verification failed, please Login again.')
                }

                const {roles, name, avatar, introduction} = data

                // roles must be a non-empty array
                if (!roles || roles.length <= 0) {
                    reject('getInfo: roles must be a non-null array!')
                }

                commit('SET_ROLES', roles)
                commit('SET_NAME', name)
                commit('SET_AVATAR', avatar)
                commit('SET_INTRODUCTION', introduction)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({commit, state, dispatch}) {
        return new Promise((resolve, reject) => {
            commit('SET_TOKEN', '')
            commit('SET_NAME', "")
            commit('SET_AVATAR', "")
            commit('SET_USERID', "")
            commit('SET_NAME', "")
            commit('SET_COM', "")

            removeToken()
            resetRouter()
            window.localStorage.removeItem('name');
            window.localStorage.removeItem('avatar');
            window.localStorage.removeItem('userId');
            window.localStorage.removeItem('comId');

            store.dispatch('permission/setCheck', false)

            resolve()
        })
    },
    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
