<template>
  <div class="navbar" >
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
               @toggleClick="toggleSideBar"/>

    <div class="selectCom" v-show="comShow" >
      <el-select v-model="comId" size="mini" placeholder="暂无所属公司或企业">
        <el-option
            v-for="item in comList"
            :key="item.comId"
            :label="item.comName"
            :value="item.comId">
        </el-option>
      </el-select>
    </div>
    <div class="right-menu">
      <div class="right-menu-item hover-effect" @click="msg">
        <el-tooltip class="item" effect="dark" content="查看消息" placement="bottom">
          <svg-icon icon-class="message"/>
        </el-tooltip>
        <el-badge :hidden="redPointMyMsg==0" :value="redPointMyMsg" :max="99"
                  style="position: absolute;left: 22px;top: -10px">
        </el-badge>
      </div>
      <div class="right-menu-item hover-effect" @click="getNoticeData">
        <el-tooltip class="item" effect="dark" content="查看更新公告" placement="bottom">
          <svg-icon style="font-size: 20px" icon-class="notice"/>
        </el-tooltip>
      </div>
      <!--        dialog-->
      <el-dialog
          title="更新公告"
          :visible.sync="dialogVisibleNotice"
          :append-to-body="true"
          top="15vh"
          width="50%">
        <div v-loading="noticeLoading">
          <div class="dialogVisibleNoticeBox" v-html="noticeContent">
          </div>
        </div>
      </el-dialog>
      <div class="right-menu-item">
      </div>

      <div class="right-menu-item" style="padding: 1px">
        <span style="font-size: 16px">{{ userName }}</span>
      </div>
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <svg-icon icon-class="user" class="user-avatar"/>
          <i class="el-icon-caret-bottom"/>
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/console/person">
            <el-dropdown-item>
              个人中心
            </el-dropdown-item>
          </router-link>
          <router-link to="/console/support">
            <el-dropdown-item>
              服务支持
            </el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Hamburger from '@/components/Hamburger'
import userLogo from '@/assets/user.png'
import {getMyCom} from '@/api/com/com'
import {getNoticeList} from "@/api/sys/sys_notice";

export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisibleNotice: false,
      comList: [
        {
          comId: null,
          comName: null
        }
      ],
      userName: '',
      comId: null,
      userLogo: userLogo,
      comShow: false,
      totalRP: 0,
      noticeLoading: false,
      noticePages: {
        orderFiled: 'createdAt',
        orderType: 'desc',
        pageNum: 1,
        pageSize: 100,
        search: '1',
        searchFiled: 'noticeType',
      },
      noticeContent: '',
    }
  },
  components: {
    Hamburger,
  },
  computed: {
    ...mapGetters([
          'sidebar',
          'avatar',
          'device',
          'redPointMyMsg',
        ]
    ),
    workshopName() {
      return decodeURIComponent(this.$store.getters.workshopName)
    }
  },
  mounted() {
    this.getUserName();
    this.getCom();
  },
  methods: {
    getCom() {
      getMyCom().then(res => {
        this.comList = res.data;
        if (this.comList) {
          this.comShow = true;
          if (this.comList.length != 0) {
            this.comId = this.comList[0].comId
          }
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    changeCom() {

    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login`)
    },
    getUserName() {
      this.userName = this.$store.getters.name
    },
    msg() {

      this.$router.push({path: '/console/person/msg', query: {}}) //带参跳转
    },
    docs() {
      window.open('https://ai.unicde.com/doc/help/', '_blank')
    },
    getNoticeData() {
      this.dialogVisibleNotice = true;
      this.noticeLoading = true;
      getNoticeList(this.noticePages).then(res=>{
          let list = res.data.records
          for (let item of list){
              if (item.noticeStatus === 1){
                  this.noticeContent = item.noticeContent;
                  break
              }
          }
          this.noticeLoading = false;
      }).catch(err=>{
          console.log(err);
          this.noticeLoading = false;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.selectCom {
  float: left;
  margin-top: 11px;

  /deep/ .el-select,
  /deep/ .el-input,
  /deep/ .el-input__inner {
  }
}

.selectComLayout {
  /deep/ .el-select,
  /deep/ .el-input,
  /deep/ .el-input__inner {
    background-color: rgba(255, 255, 255, 0.1);
    color: #333333;
    border: 0px;
    border-radius: 0px;
  }
}

.name {
  cursor: pointer;
}

.navbar {
  color: #656565;
  height: 50px;
  overflow: hidden;
  position: relative;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      position: relative;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .left-menu-item {
      float: left;
      display: inline-block;
      font-size: 20px;
      vertical-align: text-bottom;
      cursor: pointer;
    }

    .help_icon {
      display: inline-block;
      font-size: 20px;

      vertical-align: text-bottom;
      cursor: pointer;
    }


    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 36px;
          height: 36px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}

.dialogVisibleNoticeBox {
  width: 100%;
  height: 50vh;
  overflow: auto;
}
</style>
