import request from "@/api"

// 公共系统 - 更新内容的列表
export function getNoticeList(data) {
    return request({
        url: '/notice/noticeList',
        method: 'post',
        params: {
            orderFiled: data.orderFiled,
            orderType: data.orderType,
            pageNum: data.pageNum,
            pageSize: data.pageSize,
            search: data.search,
            searchFiled: data.searchFiled
        }
    })
}
