<template>
  <div class="has-logo" style="text-align: left; transition: all .3s">
    <logo :collapse="isCollapse" style="margin-bottom: 10px"/>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
          :default-active="activeMenu"
          :collapse="isCollapse"
          :background-color="variables.nodeMenuBg"
          :text-color="variables.menuText"
          :unique-opened="true"
          :active-text-color="variables.menuActiveText"
          :default-openeds="openlist"
          @select="handleSelect"
          :collapse-transition="false">
        <sidebar-item
            v-for="route in getRoutes()"
            :key="route.path"
            :item="route"
            :base-path="route.path"/>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import {isNull} from "@/utils";

export default {
  components: {SidebarItem, Logo},
  computed: {
    ...mapGetters(["permission_routes", "sidebar"]),
    ...mapState({
      nightMode: state => state.app.nightMode,
    }),
    activeMenu() {
      let {meta, path} = this.$route;
      // // if set path, the sidebar will highlight the path you set
      if (!isNull(meta.title) && meta.title != undefined) return path
      return this.activeIndex
    },

    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  watch: {
    'nightMode': {
      handler(newValue, oldValue) {
        this.changeNightMode(newValue.isNightMode)
      },
      deep: true,
    }
  },
  data() {
    return {
      activeIndex: '',
      openlist: ["/platform/model"],
      variables: {
        menuText: "#333333",
        menuActiveText: "#009966",
        nodeMenuBg: "#f1f1f1",
      },
    };
  },
  methods: {
    handleSelect(keyPath) {
      sessionStorage.setItem('platformPath', keyPath);
      this.activeIndex = keyPath
    },
    getRoutes() {
      let reg = new RegExp(/platform/);
      let ret = [];
      for (let i = 0; i < this.permission_routes.length; i++) {
        if (this.permission_routes[i].path.match(reg)) {
          ret.push(this.permission_routes[i]);
        }
      }
      return ret;
    },
    changeNightMode(mode){
      if (mode){
        this.variables = {
          menuText: "#ffffff",
          menuActiveText: "#009966",
          nodeMenuBg: "#6b6b6b",
        }
        document.getElementsByTagName('body')[0].style.setProperty('--menuBg', '#6b6b6b')
        document.getElementsByTagName('body')[0].style.setProperty('--nodeMenuHover', '#858585')
        document.getElementsByTagName('body')[0].style.setProperty('--subMenuBg', '#575757')
        document.getElementsByTagName('body')[0].style.setProperty('--subMenuHover', '#858585')
        document.getElementsByTagName('body')[0].style.setProperty('--menuArrowIcon', '#e0e0e0')
      }
      else {
        this.variables = {
          menuText: "#333333",
          menuActiveText: "#009966",
          nodeMenuBg: "#f1f1f1",
        }
        document.getElementsByTagName('body')[0].style.setProperty('--menuBg', '#f1f1f1')
        document.getElementsByTagName('body')[0].style.setProperty('--nodeMenuHover', '#ffffff')
        document.getElementsByTagName('body')[0].style.setProperty('--subMenuBg', '#e7e7e7')
        document.getElementsByTagName('body')[0].style.setProperty('--subMenuHover', '#ffffff')
        document.getElementsByTagName('body')[0].style.setProperty('--menuArrowIcon', '#6c6c6c')
      }
    },
  },
  mounted() {
    this.activeIndex = sessionStorage.getItem('platformPath') || '/console/platform/model'
  },
  beforeDestroy() {
    this.changeNightMode(false)
  },
};
</script>
