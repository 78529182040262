import request from "@/api";

// base64图片上传到Oss
export function base64Img(data) {
    return request({
        url: '/oss/file/baseImg',
        method: 'post',
        data: {
            imgStr: data,
        }
    })
}

// base64图片上传到Oss
export function baseFile(data) {
    return request({
        url: '/oss/file/uploadFile',
        method: 'post',
        data: {
            imgStr: data,
        }
    })
}

//删除图片
export function deleteImg(data) {
    return request({
        url: '/oss/file/delete',
        method: 'post',
        params: {
            fileUrl: data,
        }
    })
}

//读取文件流
export function getFile(data) {
    return request({
        url: '/oss/file/fileData',
        method: 'post',
        params: {
            filePath: data,
        }
    })
}

