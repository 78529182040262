import Cookies from 'js-cookie'

const TokenKey = 'qcai_token'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    // expires 过期时间
    // 1 = 1天 = 24小时
    // 1/24 = 1个小时
    var in30Minutes = 1 / 4;
    return Cookies.set(TokenKey, token, {
        expires: in30Minutes
    })
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}
