export var ossHome = process.env.VUE_APP_OSS
export var ossUpload = process.env.VUE_APP_BASE_API + "sys/file/uploadFile";

// 判断是否为空
export function isNull(item) {
    return item === null || item === '' || item === undefined || item === 'undefined' || item === '{}' || item === '[]';
}

// 判断是否为数组
export function isNumber(val) {
    var regPos = /^\d+(\.\d+)?$/; //非负浮点数
    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
    if (regPos.test(val) && regNeg.test(val)) {
        return true;
    } else {
        return false;
    }
}


/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
    if (!source && typeof source !== 'object') {
        throw new Error('error arguments', 'deepClone')
    }
    const targetObj = source.constructor === Array ? [] : {}
    Object.keys(source).forEach(keys => {
        if (source[keys] && typeof source[keys] === 'object') {
            targetObj[keys] = deepClone(source[keys])
        } else {
            targetObj[keys] = source[keys]
        }
    })
    return targetObj
}

/**
 * 获取 时间戳 + 随机字符串
 * @param length
 * @returns {string}
 * @constructor
 */
export function getUid(length) {
    return parseInt(new Date().getTime() / 1000) + randomCode(length)
}


/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
    if (type === 'start') {
        return new Date().getTime() - 3600 * 1000 * 24 * 90
    } else {
        return new Date(new Date().toDateString())
    }
}

//用于生成uuid
function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}

export function guid() {
    return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
}


export function randomCode(num) {
    var str = 'abcdefghijklmnpqrstuvwxyz123456789'
    var _code = ''
    for (var i = 0; i < num; i++) {
        var index = parseInt(Math.random() * str.length) //不可能大于36
        _code += str[index]
    }
    return _code;
}

export function checkImg(ext) {
    ext = ext.toLowerCase()
    return ext === "png" || ext === "jpg" || ext === "bmp" || ext === "jpeg";
}

export function checkPreviewFileSuffix(ext) {
    ext = ext.toLowerCase()
    return ext !== 'zip' && ext !== 'rar'
}

export function checkPreviewFileSize(fileSize) {
    if (fileSize.endsWith('GB')) return false
    else return !(fileSize.endsWith('MB') && Number(fileSize.substring(0, fileSize.indexOf('MB'))) > 50);
}
