<template>
  <div>
    <div id="apps" class="body" style="background: #F3F4F5;">
      <div class="topbar">
        <div
            style="max-width: 1400px;height: 100%;margin: 0 auto;position: relative"
        >
          <div class="left">
            <img
                :src="imgLogo"
                alt="logo"
                class="toplogo"
                :style="{ left: screenWidth > 1400 ? '50px' : '50px' }"
            />
          </div>
          <div
              class="right"
              :style="{ marginRight: screenWidth > 1400 ? '80px' : '50px' }"
          >
            <div class="rightItem">
              <div class="itemlist">
                <div
                    class="pageItem"
                    @click="gotoHome()"
                    :class="{ NvTitle: NvTitle === 0 }"
                    @mouseenter="moveSlider(0)"
                    @mouseleave="leaveSlider"
                >
                  首页
                </div>
                <div
                    class="pageItem"
                    @click="gotoAIService()"
                    :class="{ NvTitle: NvTitle === 1 }"
                    @mouseenter="moveSlider(1)"
                    @mouseleave="leaveSlider"
                >
                  青橙AI
                </div>
                <div
                    class="pageItem"
                    @click="gotoSolution()"
                    :class="{ NvTitle: NvTitle === 2 }"
                    @mouseenter="moveSlider(2)"
                    @mouseleave="leaveSlider"
                >
                  产品与解决方案
                </div>

                <div
                    class="pageItem"
                    @click="gotoCase()"
                    :class="{ NvTitle: NvTitle === 3 }"
                    @mouseenter="moveSlider(3)"
                    @mouseleave="leaveSlider"
                >
                  应用案例
                </div>
                <div
                    class="pageItem"
                    @click="gotoEcology()"
                    :class="{ NvTitle: NvTitle === 4 }"
                    @mouseenter="moveSlider(4)"
                    @mouseleave="leaveSlider"
                >
                  生态合作
                </div>
                <div
                    class="pageItem"
                    @click="gotoAbout()"
                    :class="{ NvTitle: NvTitle === 5 }"
                    @mouseenter="moveSlider(5)"
                    @mouseleave="leaveSlider"
                >
                  关于我们
                </div>
                <div class="pageItemC">
                  <div class="controlBtn" @click="gotoSetting()">控制台</div>
                </div>
                <div id="sliderBox"></div>
              </div>

              <div class="userItem">
                <div v-if="checkLogin()" class="loginBtn" @click="gotoLogin()">
                  登录 / 注册
                </div>
                <div v-else class="userInfo">
                  <el-dropdown trigger="hover" placement="bottom">
                    <span class="el-dropdown-link userName">
                      {{ userName }}
                      <el-badge
                          type="primary"
                          :hidden="totalAll == 0"
                          :value="totalAll"
                          :max="99"
                          style="position: relative;top: 4px;"
                      >
                      </el-badge>
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="downLabel">
                      <el-dropdown-item @click.native="gotoPerson"
                      >我的资料
                      </el-dropdown-item
                      >
                      <el-dropdown-item @click.native="logout"
                      >退出登录
                      </el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            style="background-color: #F4F4F4;width: 1200px;height: 150px;margin: 0 auto 0;display: flex;"
            v-if="productAndSolution"
            @mouseleave="productAndSolution = false"
        >
          <div style="width: 300px;height:150px;">
            <div
                style="height: 70px;text-align: center;cursor: pointer;"
                :class="{ solutionMenuIndexSelected: solutionMenuIndex === 0 }"
                @click="solutionMenuClick(0)"
            >
              青橙AI服务产品
            </div>
            <div
                style="height: 70px;text-align: center;cursor: pointer;"
                :class="{ solutionMenuIndexSelected: solutionMenuIndex === 1 }"
                @click="solutionMenuClick(1)"
            >
              行业解决方案
            </div>
          </div>
          <span
              style="background-color: #999999;width: 1px;height: 150px;"
          ></span>
          <div style="width: 900px;height:150px;">
            <div
                v-for="item in rightItemList"
                @click="gotoProductAnSolutionItem(item.path)"
                style="height: 50%;width:33%;text-align: center;float:left;"
            >
              <span style="cursor: pointer;" class="solutionItem">{{
                  item.title
                }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="sidebar">
        <div class="icon1" @click="goCooperation">
          <svg-icon icon-class="join" style="font-size: 28px"/>
          <span>加入</span>
        </div>
        <div class="icon1" @click="goCustomerService">
          <svg-icon icon-class="msg" style="font-size: 28px"/>
          <span>咨询</span>
        </div>
        <div class="icon1" @click="goBackTop">
          <svg-icon icon-class="top" style="font-size: 28px"/>
          <span>TOP</span>
        </div>
      </div>

      <router-view @getLoading="getLoading"></router-view>

      <div class="footer">
        <div class="content">
          <div class="contentBox1">
            <div style="margin-bottom: 30px">
              <span
                  style="font-size: 17px;margin-right: 20px;font-weight: bolder"
              >订阅通讯</span
              >
              <span style="font-size: 16px;">SUBSCRIBE TO OUR NEWSLETTER</span>
            </div>
            <div>
              <span class="contentBox1Btn" @click="goFooter(0)">网站首页</span>
              <span class="contentBox1Btn" @click="goFooter(1)">应用案例</span>
              <span class="contentBox1Btn" @click="goFooter(2)">生态合作</span>
              <span class="contentBox1Btn" @click="goFooter(3)">关于我们</span>
            </div>
            <div class="buttom1" @click="goway()" style="margin-top:30px;">
              粤ICP备2022099015号
            </div>
          </div>
          <div class="contentBox2">
            <div style="margin-bottom: 30px">
              <span>版权所有 ©2022 青橙智能科技有限公司</span>
            </div>
            <div>
              <span>专注AI深度学习，赋能品质智能化管控</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgLogo from '@/assets/aiqcloud/home/logo.png'
import {isNull} from '@/utils'
import {mapGetters} from 'vuex'

export default {
  name: 'HomeLayout',
  data() {
    return {
      userName: this.$store.getters.name,
      productAndSolution: false,
      imgLogo: imgLogo,
      activeIndex: '1',
      activeIndex2: '1',
      totalAll: 0,
      totalMsg: 0,
      slider: {
        width: '',
        left: '',
      },
      NvTitle: 0,
      slideBox: [],
      aiItemList: [
        {title: 'AI建模平台', path: '/solution/model'},
        {title: '协同标注平台', path: '/solution/mark'},
        {title: '数据增广平台', path: '/solution/expand'},

        {title: '可编程视觉模组', path: '/solution/visual'},
        {title: '边缘一体机', path: '/solution/edge'},
        {title: 'AOI检测软件', path: '/solution/aoi'},
      ],
      solutionItemList: [
        {title: '包装行业解决方案', path: '/solution/packaging'},
        {title: '新能源行业解决方案', path: '/solution/energy'},
        {title: '医疗行业解决方案', path: '/solution/medicine'},
        {title: '光伏行业解决方案', path: '/solution/photovoltaic'},
        {title: '3C电子行业解决方案', path: '/solution/electronics'},
        {title: '芯片行业解决方案', path: '/solution/chip'},
      ],
      rightItemList: [],
      solutionMenuIndex: 0,
      slideBoxMax: [
        {
          name: '首页',
          left: '26px',
          width: '40px',
        },
        {
          name: '青橙AI',
          left: '118px',
          width: '56px',
        },
        {
          name: '产品与解决方案',
          left: '226px',
          width: '120px',
        },
        {
          name: '应用案例',
          left: '398px',
          width: '72px',
        },
        {
          name: '生态合作',
          left: '522px',
          width: '72px',
        },
        {
          name: '关于我们',
          left: '646px',
          width: '72px',
        },
      ],
      slideBoxMin: [
        {
          name: '首页',
          left: '16px',
          width: '40px',
        },
        {
          name: '青橙AI',
          left: '88px',
          width: '56px',
        },
        {
          name: '产品与解决方案',
          left: '176px',
          width: '120px',
        },
        {
          name: '应用案例',
          left: '328px',
          width: '72px',
        },
        {
          name: '生态合作',
          left: '432px',
          width: '72px',
        },
        {
          name: '关于我们',
          left: '536px',
          width: '72px',
        },
      ],
      screenWidth: null,
    }
  },
  mounted() {
    this.getScreen()
    let route = this.$route.path
    this.changeSliderBox(route)
    let _this = this
    window.onresize = () => {
      _this.getScreen()
      _this.moveSlider(_this.NvTitle)
    }
  },
  beforeDestroy() {
    window.onresize = null
  },
  watch: {
    // 监听,当路由发生变化的时候执行
    $route(to, from) {
      let route = to.path
      this.changeSliderBox(route)
    },
  },
  computed: {
    ...mapGetters(['redPointMyMsg']),
  },
  methods: {
    solutionMenuClick(index) {
      this.rightItemList = index === 0 ? this.aiItemList : this.solutionItemList
      this.solutionMenuIndex = index
    },
    gotoProductAnSolutionItem(path) {
      if (!isNull(path)) {
        this.$router.push({path: path})
        this.productAndSolution = false
      }
    },
    getScreen() {
      this.screenWidth = document.body.clientWidth
      if (this.screenWidth > 1400) {
        this.slideBox = this.slideBoxMax
      } else {
        this.slideBox = this.slideBoxMin
      }
      let pageItem = document.getElementsByClassName('pageItem')
      pageItem.forEach((item) => {
        if (this.screenWidth > 1400) {
          item.style.padding = '0 30px'
        } else {
          item.style.padding = '0 20px'
        }
      })
    },
    getLoading(data) {
      if (data == true) {
        document.getElementById('apps').style.display = 'block'
        document.getElementById('appsLoading').style.display = 'none'
      } else if (data == false) {
        document.getElementById('apps').style.display = 'none'
        document.getElementById('appsLoading').style.display = 'block'
      }
    },
    checkLogin() {
      return isNull(this.userName)
    },
    //跳转登录界面
    gotoLogin() {
      this.$router.push({path: '/login'})
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    async gotoHome() {
      this.NvTitle = 0
      await this.$router.push({path: '/home'})
      // await window.location.reload()
    },
    gotoAIService() {
      this.NvTitle = 1
      this.$router.push({path: '/ai'})
    },
    gotoSolution() {
      this.NvTitle = 2
      this.$router.push({path: '/solution'})
    },
    gotoCase() {
      this.NvTitle = 3
      this.$router.push({path: '/case'})
    },
    gotoEcology() {
      this.NvTitle = 4
      this.$router.push({path: '/ecology'})
    },
    gotoAbout() {
      this.NvTitle = 5
      this.$router.push({path: '/about'})
    },
    gotoSetting() {
      sessionStorage.removeItem("consolePath")
      var {href} = this.$router.resolve({
        path: '/console/workbench',
      })
      window.open(href, '_blank')
    },
    goFooter(index) {
      let path = ''
      switch (index) {
        case 0:
          this.gotoHome()
          return
        case 1:
          path = '/case'
          break
        case 2:
          path = '/ecology'
          break
        case 3:
          path = '/about'
          break
      }
      this.$router.push(path)
    },

    goway() {
      window.open("https://beian.miit.gov.cn/")
    },
    gotoPerson() {
      this.$router.push('/console/person/info')
    },
    gotoMsg() {
      this.$router.push('/console/person/msg')
    },
    gotoTender() {
      this.$router.push('/market/mine/tender')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push({path: '/login'})
    },
    goCustomerService() {
      this.$router.push('/console/support/work-order')
    },
    goCooperation() {
      this.$router.push('/ecology')
    },
    //回到顶部
    goBackTop() {
      // window.scrollTo({
      //     top: 0,
      //     behavior: "smooth"
      // });

      // 获取当前位置方法
      const currentY =
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop
      this.scrollAnimation(currentY, 0)
    },
    scrollAnimation(currentY, targetY) {
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY
      let _currentY = currentY
      //来兼容不支持requestAnimationFrame的浏览器
      window.requestAnimFrame = (function () {
        return (
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            function (callback) {
              window.setTimeout(callback, 1000 / 60)
            }
        )
      })()
      window.requestAnimFrame(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 5)
        _currentY += dist
        window.scrollTo(_currentY, currentY)
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 5 || needScrollTop < -5) {
          this.scrollAnimation(_currentY, targetY)
        } else {
          window.scrollTo(_currentY, targetY)
        }
      })
    },
    changeSliderBox(route) {
      let i = document.getElementById('sliderBox')
      if (/^\/home/.test(route)) {
        i.style.left = this.slideBox[0].left
        i.style.width = this.slideBox[0].width
        this.NvTitle = 0
      } else if (/^\/ai/.test(route)) {
        i.style.left = this.slideBox[1].left
        i.style.width = this.slideBox[1].width
        this.NvTitle = 1
      } else if (/^\/solution/.test(route)) {
        i.style.left = this.slideBox[2].left
        i.style.width = this.slideBox[2].width
        this.NvTitle = 2
      } else if (/^\/case/.test(route)) {
        i.style.left = this.slideBox[3].left
        i.style.width = this.slideBox[3].width
        this.NvTitle = 3
      } else if (/^\/ecology/.test(route)) {
        i.style.left = this.slideBox[4].left
        i.style.width = this.slideBox[4].width
        this.NvTitle = 4
      } else if (/^\/about/.test(route)) {
        i.style.left = this.slideBox[5].left
        i.style.width = this.slideBox[5].width
        this.NvTitle = 5
      }
    },
    moveSlider(num) {
      let i = document.getElementById('sliderBox')
      this.slider = {
        width: i.style.width,
        left: i.style.left,
      }
      if (num == 0) {
        i.style.left = this.slideBox[0].left
        i.style.width = this.slideBox[0].width
      } else if (num == 1) {
        i.style.left = this.slideBox[1].left
        i.style.width = this.slideBox[1].width
      } else if (num == 2) {
        i.style.left = this.slideBox[2].left
        i.style.width = this.slideBox[2].width
        this.rightItemList = this.aiItemList
        this.solutionMenuIndex = 0
      } else if (num == 3) {
        i.style.left = this.slideBox[3].left
        i.style.width = this.slideBox[3].width
      } else if (num == 4) {
        i.style.left = this.slideBox[4].left
        i.style.width = this.slideBox[4].width
      } else if (num == 5) {
        i.style.left = this.slideBox[5].left
        i.style.width = this.slideBox[5].width
      }
      this.productAndSolution = num == 2
    },
    leaveSlider() {
      let route = this.$route.path
      this.changeSliderBox(route)
    },
  },
}
</script>
<style scoped lang="scss">
.body {
  text-align: center;
  font-family: '微软雅黑';
  margin: 0;
  padding: 0;
  min-width: $min-width2;
}

.solutionMenuIndexSelected {
  color: $base-color;
}
.buttom1 {
  cursor: pointer;
}

.solutionItem:hover {
  color: $base-color;
}

.topbar {
  background-color: #ffffff;
  height: 80px;
  position: relative;
  z-index: 10;
  width: 100%;
  margin: 0px auto 0;
}

.topbar {
  line-height: 80px;
  font-size: 16px;

  .left {
    float: left;
  }

  .toplogo {
    object-fit: none;
    position: absolute;
    top: 35%;
    margin-left: 50px;
    margin-top: -15px;
  }

  .topname {
    float: left;
    margin-left: 130px;
    font-size: 22px;
    font-weight: bolder;
    color: $base-color;
  }

  .right {
    float: right;
  }

  .rightItem {
    float: left;
  }

  .pageItem {
    float: left;
    padding: 0 30px;
    cursor: pointer;
    position: relative;
  }

  .pageItemC {
    float: left;
    padding: 0;
    margin: 0 10px;
    position: relative;
  }

  .controlBtn {
    width: 94px;
    height: 46px;
    line-height: 46px;
    margin-top: 17px;
    color: white;
    font-size: 18px;
    letter-spacing: 1px;
    background-color: #009966; /* 不支持线性的时候显示 */
    //background-image: linear-gradient(45deg, $base-color 0%, #00dbd3 50%, $base-color2 100%);
    background-size: 500% auto;
    border-radius: 4px;
    cursor: pointer;
    opacity: 1;
    transition: background 4s, opacity 0.3s;
  }

  .loginBtn {
    width: 95px;
    color: #009966;
    font-size: 14px;
    height: 30px;
    margin-top: 25px;
    line-height: 30px;
    border: 1px solid #009966;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
  }

  .loginBtn:hover {
    color: $base-color2;
    border: 1px solid $base-color2;
  }

  .controlBtn:hover {
    background-position: center right;
    opacity: 0.8;
  }

  .itemlist {
    float: left;
    position: relative;
  }

  .userItem {
    float: left;
    margin-left: 20px;

    .btnspan {
      background-color: $base-color2;
      color: white;
      padding: 6px 10px;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.userName {
  font-weight: 600;
  cursor: pointer;
}

.downLabel {
  background-color: #ffffff;
  margin-top: -40px;
}

.el-popper[x-placement^='bottom'] {
  margin-top: -15px !important;
}

/* footer */
.footer {
  width: 100%;
  height: 300px;
  background-color: #333b47;
  text-align: center;
  font-size: 14px;
  clear: both;
  position: relative;

  .content {
    overflow: hidden;
    margin: 0 auto;
    width: $min-width2;
    height: 100%;
    box-sizing: border-box;
    padding: 60px 0;
    color: #ffffff;
    position: relative;
  }

  .contentBox1 {
    float: left;
    width: 70%;
    height: 70px;
    box-sizing: border-box;
    line-height: 20px;
    font-size: 15px;
    text-align: left;
    letter-spacing: 1px;
    color: #ffffff;
    border-right: 1px solid #ffffff;
  }

  .contentBox1Btn {
    margin-right: 50px;
    cursor: pointer;
  }

  .contentBox1Btn:hover {
    border-bottom: 1px solid #ffffff;
  }

  .contentBox2 {
    float: left;
    width: 30%;
    height: 70px;
    line-height: 20px;
    font-size: 15px;
    text-align: right;
    letter-spacing: 1px;
    color: #ffffff;
  }

  .left {
    float: left;
    margin-left: 7%;
    text-align: left;
    font-family: '微软雅黑 Light', serif;
    overflow: hidden;

    .p1 {
    }

    .p2 {
      font-size: 22px;
    }

    .p3 {
    }
  }

  .right {
    float: right;
    margin-right: 3%;
    text-align: left;
    overflow: hidden;
    font-family: '微软雅黑 Light', serif;

    .sub {
      margin-left: 10px;
    }

    .page p {
      width: 160px;
      float: left;
      cursor: pointer;
    }
  }

  .bottom {
    text-align: left;
    clear: both;

    .iconlist {
      padding-top: 20px;
      float: left;

      .icons {
        font-size: 26px;
        margin-right: 10px;
      }
    }

    .bn {
      float: left;
      padding-top: 20px;
      line-height: 26px;
      margin-left: 500px;
      color: #a0a0a0;
    }

    .rights {
      float: right;
      line-height: 26px;
      padding-top: 20px;
      margin-right: 50px;
      color: #a0a0a0;
    }
  }

  .footer-bottom {
    width: 100%;
    height: 60px;
    font-size: 12px;
    margin-top: 20px;
    line-height: 30px;
    color: #8e8e8e;
    text-align: center;
  }
}

/*sidebar*/
.sidebar {
  height: 180px;
  z-index: 100;
  float: right;
  position: fixed;
  bottom: 60px;
  right: 30px;
}

.sidebar {
  width: 60px;
  overflow: hidden;
  transition: all 0.3s ease 0s;

  .icon1 {
    height: 60px;
    width: 60px;
    box-sizing: border-box;
    padding: 6px 15px 18px;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.2);
    color: $base-color;
    float: left;
    cursor: pointer;
  }

  .icon1:hover {
    background-color: $base-color;
    color: #ffffff;
    transition: all 0.3s ease 0s;
  }
}

.sidebar:hover {
  background: white;
}

.sidebar span {
  font-size: 13px;
  line-height: 30px;
}

#sliderBox {
  height: 3px;
  width: 50px;
  position: absolute;
  left: 21px;
  bottom: 0px;
  background-color: $base-color;
  transition: all 0.3s ease;
}

.NvTitle {
  font-weight: bolder;
}
</style>

<style media="screen" type="text/css" lang="scss" scoped>
//加载时显示
#appsLoading {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

#appsLoading span {
  position: absolute;
  top: calc(50% + 50px);
  left: 50%;
  display: block;
  font-size: 20px;
  color: $base-color;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.sk-chase {
  width: 60px;
  height: 60px;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: $base-color;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}

.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}

.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
</style>
