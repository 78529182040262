const getters = {
    sidebar: state => state.app.sidebar,
    navbar: state => state.app.navbar,
    device: state => state.app.device,
    nightMode: state => state.app.nightMode,
    avatar: state => state.user.avatar,
    userId: state => state.user.userId,
    name: state => state.user.name,
    comId: state => state.user.comId,
    wsId: state => state.user.wsId,
    wsName: state => state.user.wsName,
    wsType: state => state.user.wsType,
    permission_routes: state => state.permission.routes,
    check_routes: state => state.permission.checkRoutes,
    menu: state => state.permission.menu,
    token: state => state.user.token,
    redPointMyMsg: state => state.user.redPointMyMsg,
}
export default getters
