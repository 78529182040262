<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  data() {
    return {
      noticePages: {
        orderFiled: 'createdAt',
        orderType: 'desc',
        pageNum: 1,
        pageSize: 100,
        search: '1',
        searchFiled: 'noticeType',
      },
      noticeList: [],
    }
  },
  mounted() {
    //定时请求系统通知,每5分钟请求一次
    this.getNoticeData();
    const noticeInterval = setInterval(() => {
      this.getNoticeData();
    }, 300000)
    // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
    this.$once('hook:beforeDestroy', () => {
      clearInterval(noticeInterval);
    })
  },
  methods: {
    getNoticeData() {
      // getNoticeList(this.noticePages).then(res=>{
      //     let list = res.data.records
      //     let time = 0
      //     list.forEach(item => {
      //         if (item.noticeStatus === 0){
      //             let isDis = false
      //             for (let id of this.noticeList){
      //                 if (item.noticeId == id){
      //                     isDis = true;
      //                 }
      //             }
      //             if (!isDis){
      //                 this.noticeList.push(item.noticeId);
      //                 setTimeout(()=>{
      //                     this.$notify({
      //                         title: '系统提示',
      //                         message: item.noticeTitle,
      //                         type: 'warning',
      //                         position: 'bottom-right',
      //                         offset: 250,
      //                         duration: 0,
      //                     });
      //                 },time);
      //                 time += 400
      //             }
      //         }
      //     })
      // }).catch(err=>{
      //     console.log(err);
      // })
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
